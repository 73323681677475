<template>
  <div>
    <nav-bar v-on:getLatLong="onLocationClick" v-on:isDelivery="isDeliverySet" :settingData="$auth.setting"
      :singleRestaurant="singleRestaurant"></nav-bar>
    <section class="resturant-main tab-content header-top">
      <div class="slider-home-top">
        <div class="custom-container">
          <home-slider
            v-if="$auth && $auth.getVendorId() > 0 && filter.lat != '' && filter.lat != 'NaN' && this.businessResturant.is_delivery_zone_available != 'No'"
            :latitude="filter.lat" :longitude="filter.lng" :v-on:getRestaurantData="getRestaurantByBanner"
            v-on:isDeliveryHome="isDeliverySet" v-on:getMobileLatLong="onLocationClick"
            :is_pickup_delivery="filter.is_pickup_delivery" v-on:setSingleRestaurantId="getSingleRestaurant"
            :singleRestaurant="singleRestaurant" />
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!--Brand Start--->
            <home-title :title="$auth.setting.terminology && $auth.setting.terminology[0].brand_text" subtitle=""
              v-if="isShowBrand && $auth.setting && $auth.setting.product_type_id == '1'"></home-title>
            <brand
              v-if="$auth.setting && $auth.setting.product_type_id == '1' && filter.lat != '' && filter.is_pickup_delivery != ''"
              :latitude="filter.lat" :longitude="filter.lng" :is_pickup_delivery="filter.is_pickup_delivery"
              @getBrandRestaurantData="brandDataShow" />
            <!--Brand End--->

            <!---------Business Category Start-------->
            <!---------Also use single vendor Business Category Start-------->


            <!-- <home-title
              v-if="(businessCategoryItems && businessCategoryItems.length > 1 && items && items.length > 0 && this.businessResturant.is_delivery_zone_available != 'No') || filter_on_off"
              :title="($auth.setting && ($auth.setting.app_layout && $auth.setting.app_layout[0].home_page_category_title == null || $auth.setting.app_layout[0].home_page_category_title == '' ? '' : $auth.setting.app_layout[0].home_page_category_title))"
              :subtitle="$auth && $auth.setting && $auth.setting.app_layout[0] && $auth.setting.app_layout && $auth.setting.app_layout[0].home_page_sub_title">
            </home-title> -->
            <home-title
              v-if="(businessCategoryItems && businessCategoryItems.length > 0 && items && items.length > 0 && this.businessResturant.is_delivery_zone_available != 'No')"
              :title="($auth.setting && ($auth.setting.app_layout && $auth.setting.app_layout[0].home_page_category_title == null || $auth.setting.app_layout[0].home_page_category_title == '' ? '' : $auth.setting.app_layout[0].home_page_category_title))"
              :subtitle="$auth.setting && $auth.setting.app_layout && $auth.setting.app_layout[0].home_page_sub_title">
            </home-title>


            <div class="category-item-main" id="resturant-item-main"
              v-if="isBusinessCatloading && items && items.length > 0 && this.businessResturant.is_delivery_zone_available != 'No'">
              <skeleton-loader-vue v-for="count in 4" :key="count" type="string" width="100%" height="150px"
                style="border-radius: 5px" class="resturant-item busines-img-box d-flex align-items-start" />
            </div>

            <!-- <div class="homslider-cls cate-slider" :class="'cat-layout-business' + businessCategoryLayout"
              v-if="($auth && $auth.getVendorId() > 0 && businessCategoryItems.length > 1 && items && items.length > 0 && this.businessResturant.is_delivery_zone_available != 'No') || filter_on_off">
              <div class="home-slider-main" v-if="businessCategoryItems && businessCategoryItems.length > 1">
                <div v-bind="categorysettings" class="pill-nav round-arrow 8 cat-new">
                  <Category v-for="cat in businessCategoryItems" :key="cat.category_id" :item=cat
                    :businessCategoryLayout="businessCategoryLayout"></Category>
                </div>
              </div>
            </div> -->

            <!-- multi-vendor front category layout  -->
            <!-- :class="'cat-layout-business' + businessCategoryLayout" -->
            <!-- :class="{ ['cat-layout-business' + businessCategoryLayout]: true, ['cat-layout-business-horizontal' + businessCategoryLayout]: $auth && $auth.setting.category_layout_type_horizontal == '1' }" -->
            <div class="homslider-cls cate-slider"
              :class="{ ['cat-layout-business' + businessCategoryLayout]: true, ['cat-layout-business-horizontal' + businessCategoryLayout]: $auth && $auth.setting.category_layout_type_horizontal == '1' }"
              v-if="($auth && $auth.getVendorId() > 0 && businessCategoryItems.length > 1 && items && items.length > 0 && this.businessResturant.is_delivery_zone_available != 'No') && $auth.setting.category_layout_type_horizontal == '1' || filter_on_off">
              <div class="home-slider-main" v-if="businessCategoryItems && businessCategoryItems.length > 1">
                <div v-bind="categorysettings" class="pill-nav round-arrow 8 cat-new">
                  <Category v-for="cat in businessCategoryItems" :key="cat.category_id" :item=cat
                    :businessCategoryLayout="businessCategoryLayout"></Category>
                </div>
                <!-- <VueSlickCarousel v-bind="categorysettings" class="pill-nav round-arrow 8">
                  <Category v-for="cat in businessCategoryItems" :key="cat.category_id" :item=cat
                    :businessCategoryLayout="businessCategoryLayout"></Category>
                </VueSlickCarousel> -->
              </div>
            </div>
            <div class="homslider-cls cate-slider" :class="'cat-layout-business' + businessCategoryLayout"
              v-else-if="($auth && $auth.getVendorId() > 0 && businessCategoryItems.length > 1 && items && items.length > 0 && this.businessResturant.is_delivery_zone_available != 'No') && $auth.setting.category_layout_type_horizontal == '0' || filter_on_off">
              <div class="home-slider-main" v-if="businessCategoryItems && businessCategoryItems.length > 1">
                <div v-bind="categorysettings" class="pill-nav round-arrow 8 cat-new">
                  <Category v-for="cat in businessCategoryItems" :key="cat.category_id" :item=cat
                    :businessCategoryLayout="businessCategoryLayout"></Category>
                </div>
                <!-- <VueSlickCarousel v-bind="categorysettings" class="pill-nav round-arrow 8">
                  <Category v-for="cat in businessCategoryItems" :key="cat.category_id" :item=cat
                    :businessCategoryLayout="businessCategoryLayout"></Category>
                </VueSlickCarousel> -->
              </div>
            </div>


            <!-- multi-vendor front category layout  -->

            <!---------Business Category End-------->
            <!---------Restaurant Skeleton list Start-------->

            <!-- <div class="hme-res-itm mb-5" v-if="items.length == 0 && isItemLoader"> -->
            <div class="hme-res-itm mb-5" v-if="items.length > 0 && isItemLoader">
              <div class="filter-title justify-content-between align-items-center mb-4">
                <h4><skeleton-loader-vue type="string" width="24%" height="20px" style="bottom: 11px" /></h4>
                <p class="mb-0"><skeleton-loader-vue type="string" width="30%" height="20px" /></p>
              </div>
              <div id="resturant-item-main2" class="resturant-item-main resturant-item-skeleton">
                <a href="javascript:void(0)" v-for="itemcount in 6" :key="itemcount">
                  <div class="resturant-item d-flex align-items-start">
                    <skeleton-loader-vue class="skel-img-cls" type="string" />
                    <div class="resturant-data">
                      <h4><skeleton-loader-vue type="string" height="20px" width="135px" /></h4>
                      <!-- <span><skeleton-loader-vue type="string" width="65%" height="20px" /></span> -->
                      <div class="res-price-main mt-2 d-flex">
                        <div>
                          <skeleton-loader-vue type="string" width="44px" height="24px" />
                        </div>
                        <ul class="time-price d-flex justify-content-center mb-0 ms-3">
                          <li><skeleton-loader-vue type="string" width="44px" height="24px" /></li>
                          <li><span></span><skeleton-loader-vue type="string" width="44px" height="24px" /></li>
                        </ul>
                      </div>
                      <div class="offer-sec">
                        <p class="mb-0 mt-1"></p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <!---------Restaurant Skeleton list End-------->
            <!---------Restaurant list start-------->

            <div class="hme-res-itm"
              v-if="$auth.setting && ($auth.setting.product_type && $auth.setting.product_type.id == 1 && $auth.setting.business_category_item > 1) || (($auth.setting && $auth.setting.product_type && $auth.setting.product_type.id == 1 && $auth.setting.business_category_item <= 1) || ($auth.setting && $auth.setting.product_type && $auth.setting.product_type.id == 2 && $auth.setting.store_selection == 1))">
              <!-- <home-title v-if="items.length != 0" :title="$auth && $auth.getRestaurantName()" -->
              <!-- {{ $auth.setting.app_layout[0].home_page_restaurant_title }} -->
              <home-title
                v-if="(items && items.length > 0 && this.businessResturant.is_delivery_zone_available != 'No') || filter_on_off"
                :title="items.length !== 0 ? $auth.setting && $auth.setting.app_layout && $auth.setting.app_layout[0].home_page_restaurant_title : $auth.setting && $auth.setting.app_layout && $auth.setting.app_layout[0].home_page_restaurant_title"
                :subtitle="$auth.setting.app_layout[0].home_page_restaurant_subtitle">
                <filter-sort v-on:getFilterData="filterData"></filter-sort>
              </home-title>

              <!-- <div :class="'resturant-item-main' + (webVendorListingLayout == '1' ? '' : webVendorListingLayout)" -->
              <div :class="(webVendorListingLayout == '1' ? '' : webVendorListingLayout)" id="resturant-item-main"
                v-if="items && items.length > 0 && $auth && $auth.getVendorId() > 0">
                <!-- <div class="resturant-item-main flex-wrap d-flex align-items-center gap-5" -->
                <div :class="'resturant-item-main' + (webVendorListingLayout == '1' ? '' : webVendorListingLayout)"
                  v-if="this.businessResturant.is_delivery_zone_available != 'No'">
                  <item v-for="item in items" :key="item.restaurant_id" :item="item"
                    :isPickupDelivery="filter.is_pickup_delivery"
                    :isSchedule="$auth.setting.is_enable_schedule_order_after_business_hours"
                    :webVendorLayout="webVendorListingLayout" components="">
                  </item>
                </div>
                <div class="home-res-not-found mt-5" v-else>
                  <div class="nostr-img-cls">
                    <img v-lazy="require('@/assets/images/no_store.png')" />
                    <div class="no-txt-fnd">{{ $t('Service not available') }}</div>
                    <span>{{ $t('Service not available in this area. Please change location and try again.') }}</span>
                  </div>
                </div>
              </div>
            </div>

            <!---------Restaurant list end-------->

            <!--------Single vendor Start---------------->

            <!-- <div v-if="iscateMenusLoader" class="hme-cate-mnu-cls resto-cat-slider mt-3">
              <div :class="'categoryitem-maintheme' + cateMenuLayout + ' ' + 'cat-layout-business' + cateMenuLayout">
                <div class="category-item-main" id="resturant-item-main">
                  <skeleton-loader-vue v-for="count in 4" :key="count" type="string" width="100%" height="150px"
                    style="border-radius: 5px" class="resturant-item busines-img-box d-flex align-items-start" />
                </div>
                <div class="category-item-main" id="resturant-item-main">
                  <skeleton-loader-vue v-for="count in 4" :key="count" type="string" width="100%" height="150px"
                    style="border-radius: 5px" class="resturant-item busines-img-box d-flex align-items-start" />
                </div>
              </div>
            </div> -->
            <div class v-else>
              <div v-if="cateMenus.length > 0" class="hme-cate-mnu-cls resto-cat-slider">
                <home-title v-if="!isMenu" :title=$auth.setting.app_layout[0].home_page_category_title
                  :subtitle="$auth.setting.app_layout[0].home_page_sub_title"></home-title>
                <home-title v-else :title=$auth.setting.app_layout[0].home_page_category_title
                  :subtitle="$auth.setting.app_layout[0].home_page_sub_title"></home-title>

                <div :class="'categoryitem-maintheme' + cateMenuLayout + ' ' + 'cat-layout-business' + cateMenuLayout">
                  <!-- <div v-bind="menusettings" class="pill-nav round-arrow static-cat">
                    <a href="javascript:void(0);" v-for="catmenu in cateMenus" :key="catmenu.id"
                      @click="getSingleRestaurant(((!isMenu ? catmenu.id : catmenu.restaurant_menu_id)))">
                      <menu-category :menu="catmenu" :cateMenuLayout=cateMenuLayout :pagename=pagename></menu-category>
                    </a>
                  </div> -->
                  <div v-bind="menusettings" class="pill-nav round-arrow static-cat">
                    <a href="javascript:void(0);" v-for="catmenu in cateMenus" :key="catmenu.id"
                      @click="getSingleRestaurant(((!isMenu ? catmenu.id : catmenu.parent_menu_id)))">
                      <menu-category :menu="catmenu" :cateMenuLayout=cateMenuLayout :pagename=pagename></menu-category>
                    </a>
                  </div>
                  <!-- <VueSlickCarousel v-bind="menusettings" class="pill-nav round-arrow">
                    <a href="javascript:void(0);" v-for="catmenu in cateMenus" :key="catmenu.id"
                      @click="getSingleRestaurant(((!isMenu ? catmenu.id : catmenu.restaurant_menu_id)))">
                      <menu-category :menu="catmenu" :cateMenuLayout=cateMenuLayout :pagename=pagename></menu-category>
                    </a>
                  </VueSlickCarousel> -->
                </div>
              </div>

              <!-- skeleton for useing single vendor start -->
              <div class="hme-res-itm mb-5 mt-5"
                v-if="cateMenus.length == 0 && (this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0)">
                <div class="filter-title justify-content-between align-items-center mb-4">
                  <h4><skeleton-loader-vue type="string" width="24%" height="20px" style="bottom: 11px" /></h4>
                  <p class="mb-0"><skeleton-loader-vue type="string" width="30%" height="20px" /></p>
                </div>
                <div id="resturant-item-main2" class="resturant-item-main resturant-item-skeleton">
                  <a href="javascript:void(0)" v-for="itemcount in 4" :key="itemcount">
                    <div class="resturant-item d-flex align-items-start">
                      <skeleton-loader-vue class="skel-img-cls" type="string" />
                      <div class="resturant-data">
                        <h4><skeleton-loader-vue type="string" height="20px" width="135px" /></h4>
                        <!-- <span><skeleton-loader-vue type="string" width="65%" height="20px" /></span> -->
                        <div class="res-price-main mt-2 d-flex">
                          <div>
                            <skeleton-loader-vue type="string" width="44px" height="24px" />
                          </div>
                          <ul class="time-price d-flex justify-content-center mb-0 ms-3">
                            <li><skeleton-loader-vue type="string" width="44px" height="24px" /></li>
                            <li><span></span><skeleton-loader-vue type="string" width="44px" height="24px" /></li>
                          </ul>
                        </div>
                        <div class="offer-sec">
                          <p class="mb-0 mt-1"></p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <!-- skeleton for useing single vendor end -->
              <!-- <div class="home-res-not-found"
                v-if="cateMenus.length == 0 && (this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0)">
                <div class="nostr-img-cls">
                  <img src="@/assets/images/no_store.png" alt="no-store" />
                  <div class="no-txt-fnd">{{ $t('Service not available') }}</div>
                  <span>{{ $t('Service not available in this area. Please change location and try again.') }}</span>
                </div>
              </div> -->
            </div>

            <!--------Single vendor End---------------->

            <div class="home-res-not-found mt-5"
              v-if="items.length == 0 && !isItemLoader && (($auth.setting && $auth.setting.product_type && $auth.setting.product_type.id == 1 && $auth.setting.business_category_item > 1) || ((this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item <= 1)))">
              <div class="home-res-not-found"
                v-if="cateMenus.length == 0 && (this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0)">
                <div class="nostr-img-cls">
                  <img v-lazy="require('@/assets/images/no_store.png')" alt="no-store" />
                  <div class="no-txt-fnd">{{ $t('Service not available') }}</div>
                  <span>{{ $t('Service not available in this area. Please change location and try again.') }}</span>
                </div>
              </div>
            </div>
            <!--------Single vendor End---------------->

            <div class="home-res-not-found mt-5"
              v-if="items.length == 0 && !isItemLoader && (($auth.setting && $auth.setting.product_type && $auth.setting.product_type.id == 1 && $auth.setting.business_category_item > 1) || ((this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item <= 1)))">
              <div class="nostr-img-cls">
                <img v-lazy="require('@/assets/images/no_store.png')" />
                <div class="no-txt-fnd">{{ $t('Service not available') }}</div>
                <span>{{ $t('Service not available in this area. Please change location and try again.') }}</span>
              </div>
            </div>
          </div>
          <div id="touchFooter"></div>
        </div>
      </div>

      <!--------Single vendor menus tag wise data show Start---------------->

      <!-- <SingleTagwiseMenus /> -->
      <div class="picks">
        <single-tagwise-menus v-if="cateMenus.length > 0" :right="true" v-on:openProductDetailModal="openProductDeails"
          :restaurantID="restaurantID" :restaurant_on_off="restaurant_on_off" />
      </div>

      <!--------Single vendor menus tag wise data show End---------------->


      <!-- new Better Experience start -->
      <div class="cta"
        v-if="$auth.setting && $auth.setting.android_customer_app_url && $auth.setting.android_customer_app_url != 'undefined' && $auth.setting.android_customer_app_url != 'null' || $auth.setting && $auth.setting.ios_customer_app_url && $auth.setting.ios_customer_app_url != 'undefined' && $auth.setting.ios_customer_app_url != 'null'">
        <div class="container">
          <div class="cta-wrapper">
            <div class="row | align-items-center">
              <div class="col-lg-7 mb-lg-0 mb-4">
                <h2 class="cta-heading text-lg-start text-center">For Better Experience, <br>
                  Download the {{ $auth.getAppName() }} App Now</h2>
              </div>
              <div class="col-lg-5">
                <ul class="cta-list | d-flex align-items-center justify-content-center gap-4">
                  <li class="cta-box">
                    <a v-if="$auth.setting && $auth.setting.android_customer_app_url && $auth.setting.android_customer_app_url != 'undefined' && $auth.setting.android_customer_app_url != 'null'"
                      :href="$auth.setting.android_customer_app_url" target="_blank" class="cta-link">
                      <img v-lazy="require('@/assets/images/play-store.svg')" class="cta-img" alt="">
                    </a>
                  </li>
                  <li class="cta-box">
                    <a v-if="$auth.setting && $auth.setting.ios_customer_app_url && $auth.setting.ios_customer_app_url != 'undefined' && $auth.setting.ios_customer_app_url != 'null'"
                      :href="$auth.setting.ios_customer_app_url" target="_blank" class="cta-link">
                      <img v-lazy="require('@/assets/images/app-store.svg')" class="cta-img" alt="">
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- new Better Experience end -->

    </section>

    <!-------Business Close Modal start------->
    <div class="modal fade order-placed" data-backdrop="static" data-keyboard="false" id="businessClosed" tabindex="-1"
      aria-labelledby="businessClosedLabel" aria-hidden="true" ref="businessClosedModal" style="z-index: 9999;">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content business-modal-contnt">
          <div class="modal-body text-center">
            <p class="business-txt">{{ $auth.setting && ($auth.setting.not_available_reason ==
              '' ? 'not_available_reason' : $auth.setting.not_available_reason) }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-------Business Close Modal end------->
    <!--Alert Modal start-->
    <AlertModal v-if="isAlert" :DisplayAlertData="AlertData" v-on:modalClose="onModalCloseFun"></AlertModal>
    <!--Alert Modal End-->
    <a v-if="whatsappSupport != ''" :href="'https://wa.me/' + whatsappSupport" class="float" target="_blank">
      <img class="wp-button" v-lazy="require('@/assets/images/whatsapp-icon.png')" alt="wp-icon" />
    </a>
    <!----------with rating Order Start------------------>
    <!-- <div class="on-go-order cust-gorder" v-if="ordersList.length > 0 && isOrderDisplay">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <VueSlickCarousel v-bind="newsettings">
              <div class="oder-img" v-for="(order,index) in ordersList" :key="index" >
                <div class="odr-bk">
                  <div class="ancherslide">
                    <router-link class="slide-content"  :to="{ name: 'order-status',params: { order_id: base64_encode(order.id) }}">
                      <div class="slide-item-img"><img :src="order.icon_image" /></div>
                      <div class="slide-item-price">
                        <p class="restname">{{order.restaurant_name}}</p>
                        <p>Order : # {{order.id}}</p>                        
                      </div>
                    </router-link>
                  </div>                
                  <div class="slide-item-status">
                    <span class="item-status" v-if="order.order_status == 'Placed'">{{$t('Placed')}}</span>
                    <span class="item-status" v-if="order.order_status == 'OnTheWay'">{{$t('On The Way')}}</span>
                    <span class="item-status" v-if="order.order_status == 'ReadyToServe'">{{$t('Processing')}}</span>
                    <span class="item-status" v-if="order.order_status == 'InKitchen'">{{$t('Preparing Your Food')}}</span>
                    <span class="item-status" v-if="order.order_status == 'Arrived'">{{$t('Arrived')}}</span>
                    <router-link  :to="{ name: 'rating',params: { order_id: base64_encode(order.id) }}"> <span class="item-status deliv-rate btn" :class="order.order_status" v-if="order.order_status == 'Delivered'">{{$t('Rate Order')}}</span></router-link>
                    <p class="estmins" v-if="order.estminut > 0">Delivery in {{order.estminut}} Mins</p>
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>      
    </div> -->
    <!----------with rating Order End------------------>
    <ProductDetails ref="childComponent" :restaurantID="restaurantID" :restaurant_on_off="restaurant_on_off">
    </ProductDetails>
    <Footer></Footer>

  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import HomeSlider from "@/components/HomeSlider.vue";
import HomeTitle from "@/components/HomeTitle.vue";
import NavBar from "@/components/NavBar.vue";
import Item from "@/components/Item.vue";
import FilterSort from "@/components/FilterSort.vue";
import AlertModal from '../components/AlertModal.vue';
import Category from '../components/Category.vue';
import Footer from '../components/Footer.vue';
import moment from "moment";
import MenuCategory from "@/components/MenuCategory.vue";
import VueSkeletonLoader from 'skeleton-loader-vue';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import Brand from "@/components/Brand.vue";
import SingleTagwiseMenus from "../views/SignleTagwiseMenus.vue";
import ProductDetails from "@/components/ProductDetails.vue";

export default {
  name: "Home",
  components: {
    HomeSlider,
    HomeTitle,
    NavBar,
    Item,
    FilterSort,
    AlertModal,
    Category,
    Footer,
    MenuCategory,
    'skeleton-loader-vue': VueSkeletonLoader,
    VueSlickCarousel,
    Brand,
    SingleTagwiseMenus,
    ProductDetails
  },
  data() {
    return {
      ctr() {
        return window.google.maps.DirectionsRenderer;
      },
      pagename: 'home',
      items: [],
      restaurant_on_off: 1,
      restaurantID: '',
      sort: "0",
      cuisine_id: "",
      loading: true,
      setting: {},
      addres: "",
      latitude: "",
      filter: {
        lat: "",
        lng: "",
        city: "",
        state: "",
        is_pickup_delivery: "",
      },
      skip: 0,
      isStopCallingApi: false,
      moment: moment,
      whatsappSupport: '',
      isAlert: false,
      AlertData: {},
      businessCategoryItems: [],
      isBusinessStopCallingApi: false,
      cateMenus: [],
      isBusinessCatloading: true,
      iscateMenusLoader: true,
      isItemLoader: true,
      iscateMenuApi: false,
      isnoVendorFound: false,
      isnoVendorFoundBusiness: false,
      isScrollStart: false,
      singleRestaurant: {},
      isSingleRestaurant: '',
      cateMenuLayout: '6',
      webVendorListingLayout: '', //Vendor Layout Display
      filter_on_off: false,
      cateMenusNotFound: true, //Single Vendor In category Not Found
      businessCategoryLayout: '', //Business Category Layout,     
      businessResturant: [],
      promoDetails: {},
      isSelectCoupon: '',
      resstoId: "",
      tagWiseMenu: [],
      imgError: "",
      noImgClass: '',
      tempProduct: {},

      settings: {
        "dots": false,
        "arrows": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 8,
        "slidesToScroll": 1,
        "initialSlide": 0,
        "centerMode": false,
        "centerPadding": '40px',
        "responsive": [
          {
            "breakpoint": 1200,
            "settings": {
              "slidesToShow": 6,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 4,
              "initialSlide": 4
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2
            }
          }
        ]
      },
      ordersList: [],
      isOrderDisplay: true,
      newsettings: {
        "dots": true,
        "arrows": false,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 3,
        "slidesToScroll": 3,
        "initialSlide": 0,
        "centerMode": true,
        "centerPadding": '40px',
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 5,
              "slidesToScroll": 5,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 4,
              "initialSlide": 4
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "dots": true,
              "arrows": false,
              "infinite": false,
            }
          }
        ]
      },
      tagsettings: {
        "dots": true,
        "arrows": false,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true
            },
          },
          {
            "breakpoint": 991,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,

            },
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "dots": true,
              "arrows": false,
              "infinite": false,
            }
          }
        ]
      },
      categorysettings: {
        "dots": false,
        "arrows": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": this.slidesToShow,
        "slidesToScroll": this.slidesToShow,
        "initialSlide": 0,
        "centerMode": false,
        "centerPadding": '40px',
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": this.slidesToShow,
              "slidesToScroll": this.slidesToShow,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 4,
              "initialSlide": 4
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "dots": true,
              "arrows": false,
              "infinite": false,
            }
          }
        ]
      },
      menusettings: {
        "dots": false,
        "arrows": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 5,
        "slidesToScroll": 5,
        "initialSlide": 0,
        // "centerPadding": '40px',
        "rows": this.cateRow,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": this.slidesToShow,
              "slidesToScroll": this.slidesToShow,
              "infinite": true,
              "dots": true,
              "slidesToShow": 4,

            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 4,
              "slidesToScroll": 4,
              "initialSlide": 4
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "dots": true,
              "arrows": false,
              "infinite": false,
            }
          }
        ]
      },

      isShowBrand: false,
      slidesToShow: 3,
      isMenu: false,
      isNoCateMenu: false,
      cateRow: 1,
      singleVendorRestaurantID: 0, //Setting API in single vendor Id not definde
      // categorySubtitle: null
    };
  },


  mounted() {
    this.businessResturant.is_delivery_zone_available = localStorage.getItem('is_delivery_zone_available');
    // this.$auth.setting.app_layout.forEach((item) => {
    //   this.categorySubtitle = item.home_page_sub_title;
    // })
    this.restaurantID = this.base64_decode(localStorage.getItem("restaurantId"));
    if (this.$auth.user) {
      this.getCurrentOrdersRating()
    }
    if (this.$auth) {
      setTimeout(() => {
        if (this.$route.name == 'home' && this.$auth.setting && this.$auth.setting.product_type && ((this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item > 1) || ((this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item <= 1) || (this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 1)))) {
          // if (this.$route.name == 'home' && this.$auth.setting && this.$auth.setting.product_type && ((this.$auth.setting.product_type.id == 2 && this.$auth.setting.business_category_item > 1) || ((this.$auth.setting.product_type.id == 2 && this.$auth.setting.business_category_item <= 1) || (this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 1)))) {
          const containerEl = document.getElementById('touchFooter');
          const io = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isIntersecting) {
              this.isScrollStart = true;
              if (!this.isStopCallingApi) {
                this.$store.commit("restaurant/getRestaurantListMutation", []);
                this.getRestaurant();
              }
            }
          });
          io.observe(containerEl);
        }
        if (this.$auth.setting) {
          if (this.$auth.setting && (this.$auth.setting.support_field != 'undefined' && this.$auth.setting.support_field != null)) {
            if (this.$auth.setting.support_field.length > 0) {
              this.$auth.setting.support_field.forEach(element => {
                if (element.whatsapp) {
                  this.whatsappSupport = this.$auth.setting.country_code + element.whatsapp;
                }
              });
            }
          }

          let is_system_available = this.$auth.setting.is_system_available;
          if (is_system_available == '0') {
            // window.$(this.$refs.businessClosedModal).modal();
            window.$(this.$refs.businessClosedModal).modal('show');
          }
          var formatd = 'HH:mm:ss';
          var timedateGet = moment().format('HH:mm:ss');
          var beforeTimeGet = moment(this.$auth.setting.system_start_time).format('HH:mm:ss');
          var afterTime = moment(this.$auth.setting.system_end_time).format('HH:mm:ss');
          let afterTimeGet = (afterTime == '00:00:00') ? '23:59:00' : afterTime;
          var timed = moment(timedateGet, formatd),
            beforeTime = moment(beforeTimeGet, formatd),
            afterTime = moment(afterTimeGet, formatd);
          if (!timed.isBetween(beforeTime, afterTime)) {
            this.AlertData = {
              displayMessage: this.$auth.setting.schedule_order_after_business_hours_text,
              isSetClass: 'schedule-modal'
            };
            this.isAlert = true;
            window.$(this.$refs.AlertModal).modal();
          }

          this.businessCategoryLayout = this.$auth.setting.category_layout;
          if (this.businessCategoryLayout == '6' || this.businessCategoryLayout == '4') {
            this.slidesToShow = 3;
            this.categorysettings.slidesToShow = 3;
            this.categorysettings.slidesToScroll = 3;
          } else if (this.businessCategoryLayout == '1' || this.businessCategoryLayout == '2' || this.businessCategoryLayout == '3' || this.businessCategoryLayout == '5') {
            this.slidesToShow = 6;
            this.categorysettings.slidesToShow = 6;
            this.categorysettings.slidesToScroll = 6;
          } else {
            this.slidesToShow = 6;
            this.categorysettings.slidesToShow = 6;
            this.categorysettings.slidesToScroll = 6
          }

          this.cateMenuLayout = this.$auth.setting.master_menu_category;
          if (this.cateMenuLayout == undefined && this.cateMenuLayout == '') {
            this.cateMenuLayout = 3;
          } else if (this.cateMenuLayout == '4' || this.cateMenuLayout == '6') {
            this.menusettings.slidesToShow = 4;
            this.menusettings.slidesToScroll = 4
          }

          this.webVendorListingLayout = this.$auth.setting.vendor_listing_layout;
          if (this.webVendorListingLayout == '' || this.webVendorListingLayout == undefined) {
            this.webVendorListingLayout = 1;
          }
        }
        this.filter.lat = ((localStorage.getItem("latitude") != '' && localStorage.getItem("latitude") != null) ? parseFloat(localStorage.getItem("latitude")) : '');
        this.filter.lng = ((localStorage.getItem("longitude") != '' && localStorage.getItem("longitude") != null) ? parseFloat(localStorage.getItem("longitude")) : '');
        this.getRestaurant();

        this.isBusinessCatloading = false;
      }, 1000);
      this.$root.$on('a-store-set-event', this.aFarStoreEventHandler)
    }
  },

  computed: {
    ...mapGetters("order", ["isPickupDelivery"]),
    ...mapGetters("product", ["products", "cart"]),
    ...mapGetters("restaurant", ["getRestaurantListData", "businessCategoryListState"]),

    isBrowser() {
      return typeof window !== 'undefined';
    }

  },

  beforeDestroy() {
    this.$root.$off('a-store-set-event', this.aFarStoreEventHandler)
  },
  methods: {
    ...mapActions("restaurant", ["getRestaurants", "getBusinessCategoryList", "getRestaurantMenuCategoryList", "getCurrentLocationRestaurant", "getCategoryWiseMenu",]),
    ...mapActions("order", ["getCurrentOrdersWithratingsList", "deliveryZone"]),
    ...mapActions("product", ["getMenuProductList"]),
    ...mapMutations("product", ["clearCartItems", "getMenuProductMutationList"]),
    ...mapMutations("restaurant", ["getRestaurantListMutation", "getBusinessCateMutation"]),

    /*************
     * 
     * Close Alert Modal component 
     * 
     *************/
    onModalCloseFun(value) {
      this.isAlert = false;
    },

    /*************
     * 
     * Delivery and pickup set so this function call
     * 
     *************/
    isDeliverySet(value) {
      this.filter.is_pickup_delivery = value;
      this.$store.commit("product/clearCartItems");
      this.$store.commit("restaurant/getRestaurantListMutation", []);
      this.$store.commit("restaurant/getBusinessCateMutation", []);
      this.skip = 0;
      this.items = [];
      this.isItemLoader = true;
      this.isStopCallingApi = false;
      this.iscateMenuApi = false;
      this.getRestaurant();
    },

    /*************
     * 
     * Multivendor in filter select so this function call
     * 
     *************/
    filterData(cuisine) {
      this.$store.commit("restaurant/getRestaurantListMutation", []);
      this.isItemLoader = true;
      this.sort = cuisine.sort;
      this.skip = 0;
      this.items = [];
      if (cuisine.cusinesIds) {
        this.cuisine_id = cuisine.cusinesIds.join();
      } else {
        this.cuisine_id = "";
      }
      this.filter.lat = parseFloat(localStorage.getItem("latitude"));
      this.filter.lng = parseFloat(localStorage.getItem("longitude"));
      this.isStopCallingApi = false;
      this.getRestaurant();
    },

    /*************
     * 
     * Select location to get address and latitude and longitude to call function
     * 
     *************/
    onLocationClick(value) {
      this.iscateMenusLoader = true;
      this.cateMenus = [];
      this.items = [];
      this.isItemLoader = true;
      if (value.lat && value.lng) {
        this.filter.lat = parseFloat(value.lat);
        this.filter.lng = parseFloat(value.lng);
      } else {
        this.filter.lat = parseFloat(localStorage.getItem("latitude"));
        this.filter.lng = parseFloat(localStorage.getItem("longitude"));
      }
      this.filter.city = value.city;
      this.filter.state = value.state;
      this.isStopCallingApi = false;
      this.iscateMenuApi = false;
      setTimeout(() => {
        if (value.onclick == 'yes') {
          let host = window.location.hostname;
          // let host = window.location.hostname == 'localhost' ? 'dev.deonde.co' : window.location.hostname;
          this.deliveryZone({
            vendor_id: this.$auth.getVendorId(),
            is_langauge: this.$store.state.lang.locale,
            latitude: this.filter.lat,
            longitude: this.filter.lng,
            order_web: host
          }).then((data) => {
            localStorage.setItem('is_delivery_zone_available', data.settings.is_delivery_zone_available);
            this.businessResturant.is_delivery_zone_available = localStorage.getItem('is_delivery_zone_available');
            if (data.settings.is_delivery_zone_available == 'Yes') {
              this.filter_on_off = true;
            } else {
              this.filter_on_off = false;
            }

            // order_web: host
            // order_web: 'dev.deonde.co'
            // }).then((data) => {
            //   localStorage.setItem('is_delivery_zone_available', data.settings.is_delivery_zone_available);
            //   this.businessResturant.is_delivery_zone_available = localStorage.getItem('is_delivery_zone_available');
            //   this.businessResturant = data.settings
            //   if (this.businessResturant.is_delivery_zone_available == 'No' && this.businessResturant.not_available_delivery_zone_reason == this.businessResturant.not_available_delivery_zone_reason) {
            //     this.$toasted.error(this.businessResturant.not_available_delivery_zone_reason);
            //   }
            // this.$store.commit("restaurant/getRestaurantListMutation", []);
            this.$store.commit("product/getMenuProductMutationList", []);
            this.skip = 0;
            this.getRestaurant();
          })
        }
      }, 500);
      this.showBanner = true;
    },

    /*************
     * 
     * First home slider banner call in function emit
     * 
     *************/
    getRestaurantByBanner(value) {
      if (!value) {
        this.items = [];
      } else {
        this.getRestaurant();
      }
    },


    openProductDeails(value) {
      window.$("#item-popup").modal("show");
      window.$("#item-popup").on('shown.bs.modal', function () {
      });

      window.$("#item-popup").on('hidden.bs.modal', () => {
        if (this.$refs.childComponent) {
          this.$refs.childComponent.productDetail = null
          this.$refs.childComponent.selectedCustomisation = {
            customize_type: {
            }
          }
        }
      });
      this.$refs.childComponent.openProductModel(value.item_id);
    },

    /*************
     * 
     * Multivendor for restaurnat and business category list
     * Single Restaurnat for menu category Display
     * 
     *************/

    // async getRestaurant() {
    //   this.getSingleRestaurantData()
    //   if ((this.sort == '0' || this.sort.length <= 0) && this.cuisine_id.length <= 0) {
    //     this.filter_on_off = false;
    //   } else {
    //     this.filter_on_off = true;
    //   }

    //   if (this.filter.is_pickup_delivery == '') {
    //     this.filter.is_pickup_delivery = this.isPickupDelivery;
    //   }
    //   // if (this.$auth.setting && this.$auth.setting.product_type && ((this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item > 1) || ((this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item <= 1))) && this.filter.lat != '') {
    //   // Mutli vendor  
    //   if ((this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0) && this.filter.lat != '') {
    //     this.cateMenusNotFound = false;

    //     if (localStorage.getItem("restaurantId") == null) {
    //       this.singleVendorRestaurantID = this.$auth.setting.restaurant_id;
    //     } else {
    //       this.singleVendorRestaurantID = this.base64_decode(localStorage.getItem("restaurantId"));
    //     }


    //     if (this.$route.name == 'home' && !this.iscateMenuApi) {
    //       /********************If manully address set************ */
    //       if (localStorage.getItem("geolocation") == 'yes') {
    //         this.getCurrentLocationRestaurant({
    //           vendor_id: this.$auth.getVendorId(),
    //           is_langauge: this.$store.state.lang.locale,
    //           latitude: this.filter.lat,
    //           longitude: this.filter.lng,
    //         }).then(async (data) => {
    //           if (data.code == 200) {
    //             localStorage.setItem("restaurantId", this.base64_encode(data.Result.restaurant_id))
    //             this.singleVendorRestaurantID = data.Result.restaurant_id;
    //             await this.getSingleRestaurantData(data.Result.restaurant_id);
    //           } else {
    //             await this.getSingleRestaurantData(this.singleVendorRestaurantID);
    //           }
    //         });
    //       } else {
    //         await this.getSingleRestaurantData(this.singleVendorRestaurantID);
    //       }
    //     }


    //     //Disaplay Business Category                        
    //     let catList = {
    //       city: this.filter.city,
    //       state: this.filter.state,
    //       vendor_id: this.$auth.getVendorId(),
    //       latitude: this.filter.lat,
    //       longitude: this.filter.lng,
    //       is_langauge: this.$store.state.lang.locale,
    //     };

    //     if (this.$auth.setting && this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item > 1) {
    //       this.iscateMenusLoader = false;
    //       if (this.businessCategoryListState && this.businessCategoryListState.length > 0) {
    //         this.businessCategoryItems = this.businessCategoryListState;
    //         this.isBusinessStopCallingApi = true;
    //       } else {
    //         if (this.$route.name == 'home' && !this.isBusinessStopCallingApi) {
    //           await this.getBusinessCategoryList(catList).then((data) => {
    //             if (data.code == 200) {
    //               this.businessCategoryItems = data.Result;
    //               this.isBusinessStopCallingApi = true;
    //               this.$store.commit("restaurant/getBusinessCateMutation", this.businessCategoryItems);
    //             } else {
    //               this.isnoVendorFoundBusiness = true;
    //             }
    //           });
    //         }
    //       }
    //     }
    //     //Disaplay Restaurant        
    //     if (this.getRestaurantListData && this.getRestaurantListData.length > 0) { //Get data from storage using vuex
    //       this.iscateMenusLoader = false;
    //       // this.items = this.getRestaurantListData;
    //       // this.skip = this.getRestaurantListData.length;
    //       this.isItemLoader = false;
    //     } else {
    //       let restData = {
    //         date: "",
    //         cityname: this.filter.city,
    //         latitude: this.filter.lat,
    //         cuisine_id: this.cuisine_id,
    //         skip: this.skip,
    //         // skip: 0,
    //         starttime: "",
    //         is_langauge: this.$store.state.lang.locale,
    //         sort_type: this.sort,
    //         user_id: "",
    //         filter_type: "",
    //         cost_range: "",
    //         vendor_id: this.$auth.getVendorId(),
    //         state: this.filter.state,
    //         delivery_type_time_slots: this.filter.is_pickup_delivery,
    //         longitude: this.filter.lng,
    //         total_record: 15,
    //       };
    //       if (this.$route.name == 'home' && !this.isStopCallingApi) {
    //         await this.getRestaurants(restData).then((data) => {
    //           this.iscateMenusLoader = false;
    //           if (data.code == 200) {
    //             this.isItemLoader = false;
    //             this.skip = this.skip + data.Result.length;
    //             // this.skip = this.skip + data.open_restaurant;
    //             if (!this.items.length) {
    //               data.Result.forEach((resItem) => {
    //                 this.items.push(resItem);
    //               });
    //             } else {
    //               data.Result.forEach((resItem) => {
    //                 let checkRest = this.items.find((elm) => elm.restaurant_id == resItem.restaurant_id);
    //                 if (!checkRest) {
    //                   this.items.push(resItem);
    //                 }
    //               });
    //             }
    //             // this.$store.commit("restaurant/getRestaurantListMutation", this.items);
    //             // if (data.open_restaurant <= 2) {
    //             //   this.getRestaurant();
    //             // }
    //             if (data.open_restaurant <= 2) {
    //               this.getRestaurant();
    //             }
    //           } else if (data.code == 101) {
    //             this.isItemLoader = false;
    //             this.isStopCallingApi = true;
    //             // this.$store.commit("restaurant/getRestaurantListMutation", this.items);
    //           } else {
    //             if (data.Result.length == 0) {
    //               this.isStopCallingApi = true;
    //               this.isnoVendorFound = true;
    //               // this.$store.commit("restaurant/getRestaurantListMutation", this.items);
    //             }
    //             this.isItemLoader = false;
    //           }
    //         });
    //       }
    //     }
    //   }
    // },


    async getRestaurant() {
      if ((this.sort == '0' || this.sort.length <= 0) && this.cuisine_id.length <= 0) {
        this.filter_on_off = false;
      } else {
        this.filter_on_off = true;
      }
      if (this.filter.is_pickup_delivery == '') {
        this.filter.is_pickup_delivery = this.isPickupDelivery;
      }
      if ((this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0) && this.filter.lat != '') {
        //Single Vendor        
        this.iscateMenusLoader = true;
        /****If Restaurant Id first getting and response restaurant id save in local storage***/
        if (localStorage.getItem("restaurantId") == null) {
          this.singleVendorRestaurantID = this.$auth.setting.restaurant_id;
        } else {
          this.singleVendorRestaurantID = this.base64_decode(localStorage.getItem("restaurantId"));
        }
        this.isBusinessCatloading = false;
        this.isItemLoader = false;
        if (this.$route.name == 'home' && !this.iscateMenuApi) {
          /********************If manully address set************ */
          if (localStorage.getItem("geolocation") == 'yes') {
            this.getCurrentLocationRestaurant({
              vendor_id: this.$auth.getVendorId(),
              is_langauge: this.$store.state.lang.locale,
              latitude: this.filter.lat,
              longitude: this.filter.lng,
            }).then(async (data) => {
              if (data.code == 200) {
                localStorage.setItem("restaurantId", this.base64_encode(data.Result.restaurant_id))
                this.singleVendorRestaurantID = data.Result.restaurant_id;
                await this.getSingleRestaurantData(data.Result.restaurant_id);
              } else {
                await this.getSingleRestaurantData(this.singleVendorRestaurantID);
              }
            });
          } else {
            await this.getSingleRestaurantData(this.singleVendorRestaurantID);
          }
        }
      } else if (this.$auth.setting && this.$auth.setting.product_type && ((this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item > 1) || ((this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item <= 1) || (this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 1))) && this.filter.lat != '') {
        // Mutli vendor        
        // if ((this.sort == '0' || this.sort.length <= 0) && this.cuisine_id.length <= 0) {
        //     this.filter_on_off = false;
        //   } else {
        //     this.filter_on_off = true;
        //   }
        this.cateMenusNotFound = false;
        //Disaplay Business Category                        
        let catList = {
          city: this.filter.city,
          state: this.filter.state,
          vendor_id: this.$auth.getVendorId(),
          latitude: this.filter.lat,
          longitude: this.filter.lng,
          is_langauge: this.$store.state.lang.locale,
        };

        if (this.$auth.setting && this.$auth.setting.product_type.id == 1 && this.$auth.setting.business_category_item > 1) {
          this.iscateMenusLoader = false;
          if (this.businessCategoryListState && this.businessCategoryListState.length > 0) {
            this.businessCategoryItems = this.businessCategoryListState;
            this.isBusinessStopCallingApi = true;
          } else {
            if (this.$route.name == 'home' && !this.isBusinessStopCallingApi) {
              await this.getBusinessCategoryList(catList).then((data) => {
                if (data.code == 200) {
                  this.businessCategoryItems = data.Result;
                  this.isBusinessStopCallingApi = true;
                  this.$store.commit("restaurant/getBusinessCateMutation", this.businessCategoryItems);
                } else {
                  this.isnoVendorFoundBusiness = true;
                }
              });
            }
          }
        }
        //Disaplay Restaurant        
        if (this.getRestaurantListData && this.getRestaurantListData.length > 0) { //Get data from storage using vuex
          this.iscateMenusLoader = false;
          // this.items = this.getRestaurantListData;
          this.skip = this.getRestaurantListData.length;
          this.isItemLoader = false;
        } else {
          let restData = {
            date: "",
            cityname: this.filter.city,
            latitude: this.filter.lat,
            cuisine_id: this.cuisine_id,
            skip: this.skip,
            starttime: "",
            is_langauge: this.$store.state.lang.locale,
            sort_type: this.sort,
            user_id: "",
            filter_type: "",
            cost_range: "",
            vendor_id: this.$auth.getVendorId(),
            state: this.filter.state,
            delivery_type_time_slots: this.filter.is_pickup_delivery,
            longitude: this.filter.lng,
            total_record: 15,
          };
          if (this.$route.name == 'home' && !this.isStopCallingApi) {
            await this.getRestaurants(restData).then((data) => {
              this.iscateMenusLoader = false;
              if (data.code == 200) {
                this.isItemLoader = false;
                this.skip = data.Result.length;
                // this.skip = this.skip + data.Result.length;
                if (!this.items.length) {
                  data.Result.forEach((resItem) => {
                    this.items.push(resItem);
                  });
                } else {
                  data.Result.forEach((resItem) => {
                    let checkRest = this.items.find((elm) => elm.restaurant_id == resItem.restaurant_id);
                    if (!checkRest) {
                      this.items.push(resItem);
                    }
                  });
                }
                // this.$store.commit("restaurant/getRestaurantListMutation", this.items);
                if (data.open_restaurant <= 2) {
                  this.getRestaurant();
                  this.isStopCallingApi = true;
                }
              } else if (data.code == 101) {
                this.isItemLoader = false;
                this.isStopCallingApi = true;
                // this.$store.commit("restaurant/getRestaurantListMutation", this.items);
              } else {
                if (data.Result.length == 0) {
                  this.isStopCallingApi = true;
                  this.isnoVendorFound = true;
                  // this.$store.commit("restaurant/getRestaurantListMutation", this.items);
                }
                this.isItemLoader = false;
              }
            });
          }
        }
      }
    },


    async getSingleRestaurantData(singleVendorRestaurantID) {
      this.getRestaurantMenuCategoryList({
        restaurant_id: singleVendorRestaurantID, //Using this id Setting API this.$auth.setting.restaurant_id,
        vendor_id: this.$auth.getVendorId(),
        is_langauge: this.$store.state.lang.locale,
      }).then(async (data) => {
        this.isMenu = false;
        this.isItemLoader = false;
        if (data.code == 200) {
          this.cateMenus = data.Result;
          this.singleRestaurant = data.restaurant_details;
          this.restaurantID = this.singleRestaurant.restaurant_id
          localStorage.setItem("restaurantId", this.base64_encode(data.restaurant_details.restaurant_id));
          this.menusettings.rows = (this.cateMenus.length > 5) ? 2 : 1;
          this.iscateMenuApi = true;
          this.cateMenusNotFound = false;
          localStorage.setItem("geolocation", '');
          this.iscateMenusLoader = false;
        } else {
          this.cateMenus = [];
          this.cateMenusNotFound = true;
          this.iscateMenuApi = true;
          this.isnoVendorFound = true;
          this.getMenuProductsData(singleVendorRestaurantID);
          this.singleRestaurant = data.restaurant_details;
          localStorage.setItem("geolocation", '');
        }
      });
      // this.getMenuProductsData(singleVendorRestaurantID);
      // this.getTagWiseRestaurantMenus(singleVendorRestaurantID)
    },

    getMenuProductsData(restid) {
      this.resstoId = localStorage.getItem("restaurantID");
      if (this.products && this.products.length > 0) {
        this.cateMenus = this.products;
        this.menusettings.rows = (this.cateMenus.length > 5) ? 2 : 1;
        // this.iscateMenuApi = true;
        // this.cateMenusNotFound = false;
        this.isMenu = true;
        localStorage.setItem("geolocation", '');
        this.iscateMenusLoader = false;
      } else {
        if (this.cateMenus.length == 0) {
          this.getCategoryWiseMenu({
            date: "",
            restaurant_id: restid,
            // restaurant_id: this.resstoId,
            vendor_id: this.$auth.getVendorId(),
            skip: 0,
            starttime: "",
            is_langauge: this.$store.state.lang.locale,
            total_record: 1000,
            cityname: this.filter.city,
            delivery_type_time_slots: this.filter.is_pickup_delivery,
            menu_category_id: "0",
            latitude: this.filter.lat,
            longitude: this.filter.lng,
          }).then((data) => {
            this.isMenu = true;
            if (data.code == 200) {
              this.cateMenus = data.Result;
              this.menusettings.rows = (this.cateMenus.length > 5) ? 2 : 1;
              this.singleRestaurant = data.restaurant_details;
              localStorage.setItem("restaurantId", this.base64_encode(data.restaurant_details.restaurant_id));
              this.resstoId = data.restaurant_details.restaurant_id;
              this.iscateMenuApi = true;
              this.cateMenusNotFound = false;
              localStorage.setItem("geolocation", '');
            } else {
              this.cateMenus = [];
              this.cateMenusNotFound = true;
              this.iscateMenuApi = true;
              this.isnoVendorFound = true;
              this.singleRestaurant = data.restaurant_details;
              localStorage.setItem("geolocation", '');
              this.isNoCateMenu = true;
            }
            this.iscateMenusLoader = false;
          });
        }
      }
    },

    /*************
     * 
     * single vendor menu category Onclick to redirect Single Restautant 
     * 
     *************/
    getSingleRestaurant(id) {
      localStorage.setItem("menuCatID", id);
      this.$router.push({ name: "vendor", params: { restaurant_name: this.singleRestaurant.slug } });
      // this.$router.push({ name: "restaurant", params: { restaurant_id: this.encodeBase64(id) } });
    },

    encodeBase64(value) {
      // Ensure window.btoa is available
      return this.isBrowser ? window.btoa(value) : '';
    },

    getCurrentOrdersRating() {
      this.getCurrentOrdersWithratingsList({
        vendor_id: this.$auth.getVendorId(),
        is_langauge: this.$store.state.lang.locale,
        user_id: this.$auth.user.user_id,
        skip: 0,
        totalrecord: 20,
      }).then((data) => {
        if (data.code == 200) {
          this.ordersList = [];
          let rsults = data.Result;
          let newOrdes = [];
          rsults.forEach((rsult) => {
            let crrntday = moment().format('YYYY-MM-DD HH:mm:ss');
            if (rsult.eta_delivery_times > 0) {
              const minutes = moment.utc(rsult.eta_delivery_times * 1000).format('m');
              rsult.estminut = minutes;
            } else {
              const minutes = moment.utc(rsult.delivery_times * 1000).format('m');
              rsult.estminut = minutes;
            }
            if (moment(rsult.eta).diff(crrntday, 'minutes') > 0) {
              rsult.delayMsg = true;
            } else {
              rsult.delayMsg = false;
            }
            newOrdes.push(rsult);
          })
          this.ordersList = newOrdes;
        } else {
          this.ordersList = [];
        }
      });
    },

    brandDataShow(value) {
      if (value) {
        this.isShowBrand = true;
      } else {
        this.isShowBrand = false;
      }
    },

    aFarStoreEventHandler(val) {
      this.$store.commit("restaurant/getRestaurantListMutation", []);
      this.$store.commit("restaurant/getBusinessCateMutation", []);
      this.$store.commit("product/getMenuProductMutationList", []);
      this.skip = 0;
      this.items = [];
      this.isItemLoader = true;
      this.isStopCallingApi = false;
      this.iscateMenuApi = false;
      this.getRestaurant();
    },
  },
};
</script>
<style>
.skel-img-cls {
  width: 120px;
  height: 120px;
  margin-right: 7px;
  border-radius: 5px !important;
}

.business-txt {
  margin: 85px;
}

.picks {
  margin-top: 2.5rem !important;
}

/* .slick-slide {
  widows: 100% !important;
} */

/* .quantityInc {
  position: absolute;
  bottom: 15px;
  left: 50%;
  translate: -50% 0;
} */

.item_detail {
  border: 0 !important;
  /* margin: 0 10px; */
}

.media {
  /* margin: 0 10px; */
  /* border: 1px solid var(--theme-secondary) !important; */
  border-radius: 20px !important;
}

.slick-dots {
  margin: 10px 0 0;
  position: relative;
}

.slick-dots li.slick-active button:before {
  color: rgba(var(--theme-primary)) !important;
}
</style>
